import * as React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { page, pageHeader, pageBody } from "./about.module.css";

const AboutPage = ({ location }) => {
  return (
    <Layout>
      <Seo pageTitle="About" pagePath={location.pathname} />
      <main className={page}>
        <h1 className={pageHeader}>BeaSkyBlueについて</h1>
        <div className={pageBody}>
          <p>
            個人学習のアウトプット用サイトです。
            <br />
            せっかくお越しいただいたのに、どうでもいい内容ばかりで申し訳ございません。
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default AboutPage;
